:root {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: "Open Sans", sans-serif;
    background: #ffffff;
  }
  a {
    color: #4154f1;
    text-decoration: none;
  }
  
  a:hover {
    color: #717ff5;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "Nunito", sans-serif;
  }


  /*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-header {
    text-align: center;
    padding-bottom: 40px;
  }
  
  .section-header h2 {
    font-size: 40px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: #4154f1;
    text-transform: uppercase;
  }
  
  .section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: #012970;
  }
  
  @media (max-width: 768px) {
    .section-header p {
      font-size: 28px;
      line-height: 32px;
    }
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background: #012970;
    min-height: 40px;
    margin-top: 82px;
    color: #fff;
  }
  
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 57px;
    }
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 500;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
  }
  
  .breadcrumbs ol a {
    color: #fff;
    transition: 0.3s;
  }
  
  .breadcrumbs ol a:hover {
    text-decoration: underline;
  }
  
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #8894f6;
    content: "/";
  }

  
 
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info-box {    
    padding: 30px;
  }
  

  .contact .info-box i {
    font-size: 38px;
    line-height: 0;
    color: #0318d3;
  }
  
  .contact .info-box h3 {
    font-size: 20px;
    color: #012970;
    font-weight: 700;
    margin: 20px 0 10px 0;
  }
  
  .contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .contact .php-email-form {
    padding: 30px;
    height: 100%;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 100;
	  border-color: #dc4a38;
   	border-width: 0 0 3px 0;
   	border-style: none none solid none;
   	box-shadow: none;
     background: local;
  }
  
  .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #012970;
  }
  
  .contact .php-email-form input {
    padding: 10px 15px;
  }
 


  .contact .php-email-form textarea {
    padding: 12px 15px;
  }
  
  .contact .php-email-form button[type="submit"] {
    background: #dc4a38;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .contact .php-email-form button[type="submit"]:hover {
    background: #012970;
  }

  /*--------------------------------------------------------------
  # Footer social media icons
  --------------------------------------------------------------*/

/* @import '//codepen.io/chrisdothtml/pen/ojLzJK.css'; */
.social-btns .btn,
.social-btns .btn:before,
.social-btns .btn .fa {
  transition: all 0.35s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}
.social-btns .btn:before {
  top: 90%;
  left: -110%;
}
.social-btns .btn .fa {
  transform: scale(0.8);
}
.social-btns .btn.facebook:before {
  background-color: #3b5998;
}
.social-btns .btn.facebook .fa {
  color: #3b5998;
}
.social-btns .btn.whatsapp:before {
  background-color: #3cf;
}
.social-btns .btn.whatsapp .fa {
  color: #3cf;
}
.social-btns .btn.instagram:before {
  background-color: #dc4a38;
}
.social-btns .btn.instagram .fa {
  color: #dc4a38;
}

.social-btns .btn:focus:before,
.social-btns .btn:hover:before {
  top: -10%;
  left: -10%;
}
.social-btns .btn:focus .fa,
.social-btns .btn:hover .fa {
  color: #fff;
  transform: scale(1);
}
.social-btns {
  font-size: 0;
  text-align: center;
  position: center;
}
.social-btns .btn {
  display: inline-block;
  background-color: #fff;
  width: 50px;
  height: 50px;
  line-height: 35px;
  margin: 0 10px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 5px 15px -5px rgba(0,0,0,0.1);
  opacity: 0.99;
  align-items:center;
  border-color: #dc4a38;
}
.social-btns .btn:before {
  content: '';
  width: 120%;
  height: 120%;
  position: absolute;
  transform: rotate(45deg);
}
.social-btns .btn .fa {
  font-size: 38px;
  vertical-align: middle;
}



  /*--------------------------------------------------------------
  # services
  --------------------------------------------------------------*/
.services{
    margin: 0;
    padding: 0;
    min-height: 10vh;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;   
    font-family: sans-serif;
    background: #ffffff;
    margin-top: -40px;
}
.services .container {
    width: 1000px;
    position: center;
    display: flex;
    justify-content: space-between;
    background: #ffffff; 
    
}

.services .container .card {
    align-items: center;
    border-radius: 10px;
    border:none;
    background: local;
}

.services .container .card .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.7s;
    z-index: 1;
    
}
.services .container .card .face.face1 .content{
    display: flex;
}
.services .container .card .face.face1 .icon {
    padding-top: 40px;
   text-align: center;
   justify-content: center;
   align-items: center;
}


.services .container .card:nth-child(1) .icon {
  background: #e07768;
}
.services .container .card:nth-child(2) .icon {
  background: #6eadd4;
}

.services .container .card:nth-child(3) .icon {
  background: #f4a261;
}
.services .container .card:nth-child(4) .icon {
background: #8d6cab;
}
.services .container .card:nth-child(5) .icon {
background: #4aada9;
}
.services .container .card:nth-child(6) .icon {
background: #5c946e;
}
.services .services-label{
  color: black;
}

.services .container .card .icon .fa {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    transition: 0.7s;
    color: rgb(250, 7, 7);
    
}

.services .container .card .face {
    width: 300px;
    height: 200px;
    transition: 0.5s;
}

.services .container .card .face.face1 {
    position: relative;
    background: rgb(105, 97, 97);
    z-index: 1;
    transform: translateY(100px);
    
}

.services .container .card:hover .face.face1{
    background: #ad7b8c;
    transform: translateY(0px);
}

.services .container .card .face.face1 .content {
    opacity: 1;
    transition: 0.5s;
}
.services .container .card:hover .face.face1 .content {
    opacity: 1;
}


.services .container .card .face.face2 {
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0,0,0,0.8);
    transform: translateY(-100px);
}

.services .container .card:hover .face.face2{
    transform: translateY(0);
}



.services .container h5 {
  text-align: center;
    text-decoration: none;
    color: #dc4a38;
}
.services .container h5:hover {
  text-align: center;
    text-decoration: none;
    color: #012970;
}
/*----------------------------------------------------------------------------------------------------------
                        Main Hero section Blue - #012970 Orange - #dc4a38
---------------------------------------------------------------------------------------------------------*/
.hero {
    width: 100%;
    height: 100vh;
    background: url(./Assets/images/hero-bg.png) top center no-repeat;
    background-size: cover;
    
  }
  
  .hero .hero-box{
    margin-top: 30px;
  }

  .hero h1 {
    color: #012970;
    margin: 15px 0 0 0;
    font-size: 26px;
    
  }
.hero .btn-get {
    width: fit-content;
}
  
  .hero .btn-get-started {
    margin-top: 20px;
    line-height: 0;
    padding: 15px 40px;
    border-radius: 50px;
    color: #fff;
    background: #d65746;
    box-shadow: 0px 5px 30px rgba(126, 119, 139, 0.4);
    overflow: hidden;
  }

  .hero .btn-get-started span {
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;
  }
  
  .hero .hero-img {
    text-align: right;
    animation: zoominout 2.2s ease-in-out infinite alternate;
  }
  @keyframes zoominout {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(0.87);
    }
  }
  
  @media (min-width: 1024px) {
    .hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 991px) {
    .hero {
      height: auto;
      padding: 120px 0 60px 0;
    }
    .hero .hero-img {
      text-align: center;
      margin-top: 80px;
    }
    .hero .hero-img img {
      width: 80%;
    }
  }
  
  @media (max-width: 768px) {
    .hero {
      text-align: center;
    }
    .hero h1 {
      font-size: 32px;
    }
    .hero h2 {
      font-size: 24px;
    }
    .hero .hero-img img {
      width: 100%;
    }
  }

/*----------------------------------------------------------------------------------------------------------
                        Hero-img section ( person typing on computer)
---------------------------------------------------------------------------------------------------------*/

#avatar{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
}


#keyboard{
    
    width:100%;
    height:100%;
    color:black;
    font-weight:bold;
    animation: myanimation 10s infinite;
}
  @keyframes myanimation {
    0% {fill: rgb(22, 255, 1);}
    25%{fill:yellow;}
    50%{fill:rgb(11, 189, 243);}
    75%{fill:rgb(250, 150, 0);}
  }
  @keyframes myanimation2 {
    0% {fill: yellow;}
    25%{fill:red;}
    50%{fill:rgb(3, 188, 245);}
    75%{fill:rgb(171, 243, 4);}
  }
#typings {
    
    width:100%;
    height:100%;
    color:black;
    font-weight:bold;
    animation: myanimation2 10s infinite;
}

#typings path:nth-child(1){
    animation: myanimation2 10s infinite;
}

#typings path:nth-child(2){
    animation: myanimation2 10s infinite;
}

#typings path:nth-child(3){
    animation: myanimation2 10s infinite;
}

#typings path:nth-child(4){
    animation: myanimation2 10s infinite;
}

#typings path:nth-child(5){
    animation: myanimation2 10s infinite;
}

#typings path:nth-child(6){
    animation: myanimation2 10s infinite;
}

#typings path:nth-child(7){
    animation: myanimation2 10s infinite;
}

@keyframes myanimation2 {
    0% {fill: yellow;}
    25%{fill:red;}
    50%{fill:rgb(3, 188, 245);}
    75%{fill:rgb(219, 1, 1);}
    100% {fill: rgb(195, 255, 100);}
  }
/*-------------------------------------------------------------------------------------------------*/

#typings-2 :not(#path1580){
    
    width:100%;
    height:100%;
    color:black;
    font-weight:bold;
    animation: myanimation3 10s infinite;
}


#typings-2 path:nth-child(1) :not(#path1580){
    animation: myanimation3 10s infinite;
}


@keyframes myanimation3 {
    0% {fill: red;}
    25%{fill:yellow;}
    50%{fill:rgb(73, 197, 73);}
    75%{fill:rgb(175, 79, 79);}
    100% {fill: rgb(21, 183, 233);}
  }
/*-------------------------------------------------------------------------------------------------*/

#upper-body *{
    animation: body 2s ease infinite alternate;
    transform-origin: bottom;
    transform-box: content-box;
}

#lower-body  *{
    animation: body 2s ease infinite alternate;
    transform-origin: bottom;
    transform-box: content-box;
}
@keyframes body {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(1deg);
    }
}

#right-hand  *{
    animation: right-hand 1s ease-in-out alternate infinite;
    transform-origin: top;
    transform-box: content-box;
}
@keyframes right-hand {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(10deg);
    }
}


/*-----------------------------------------------------------------------------------------------------------
                    Hero font section (copany name animation)
----------------------------------------------------------------------------------------------------------*/


#logo{
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fill 0.5s ease forwards 3.8s ;
  
}

#logo path:nth-child(1){
  stroke-dasharray: 423.7928px;
  stroke-dashoffset: 423.7928px;
  animation: line-anim 2s ease forwards;
}

#logo path:nth-child(2){
  stroke-dasharray: 199.4403px;
  stroke-dashoffset: 199.4403px;
  animation: line-anim 2s ease forwards 0.8s;
}

#logo path:nth-child(3){
  stroke-dasharray: 361.4765px;
  stroke-dashoffset: 361.4765px;
  animation: line-anim 2s ease forwards 1.6s;
}

#logo path:nth-child(4){
  stroke-dasharray: 532.2488px;
  stroke-dashoffset: 532.2488px;
  animation: line-anim 2s ease forwards 2.4s;
}

#logo path:nth-child(5){
  stroke-dasharray: 352.0838px;
  stroke-dashoffset: 352.0838px;
  animation: line-anim 2s ease forwards 3.2s;
}

#logo path:nth-child(6){
  stroke-dasharray: 476.3146px;
  stroke-dashoffset: 476.3146px;
  animation: line-anim 2s ease forwards 4s;
}

#logo path:nth-child(7){
  stroke-dasharray: 429.3866px;
  stroke-dashoffset: 429.3866px;
  animation: line-anim 2s ease forwards 4.8s;
}
#logo path:nth-child(8){
  stroke-dasharray: 296.0404px;
  stroke-dashoffset: 296.0404px;
  animation: line-anim 2s ease forwards 5.6s;
}

@keyframes line-anim{
  to {
      stroke-dashoffset: 0;
  }
}

@keyframes fill{
  from {
      fill:transparent;
  }
  to {
      fill:none;
  }
}
  
/* T Y P E  W R I T E R */





@keyframes blinkk { 
  50% { border-right: 2px solid; } 
}
     
.bli{
  animation-name: blinkk ;
  animation-duration: .5s ;
  animation-timing-function: step-end ;
  animation-iteration-count: infinite ;
  animation-direction: alternate ;
  
}

  /*-------------------------------------------------------------------------------------------------------
                                      Navbar
  -------------------------------------------------------------------------------------------------------*/
  @import url("https://fonts.googleapis.com/css?family=Merriweather:900&display=swap");
  .Navbar {
    --color-primary: #dc4a38;
    --color-secondary: #F0F7EE;
    --duration: 1.5s;
    --nav-duration: calc(var(--duration) / 4);
    --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
    --space: 1rem;
    --font-primary: "Helvetica", sans-serif;
    --font-heading: "Merriweather", serif;
    --font-size: 1.125rem;
    --line-height: 1.5;
    position: absolute;
    z-index: 200;
    opacity: 0.9;
    
  }
  
  .main-navigation-toggle {
    position: fixed;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }
  .main-navigation-toggle + label  {
    position: fixed;
    top: calc(var(--space) * 1.5);
    right: calc(var(--space) * 2);
    cursor: pointer; 
    z-index: 2;
  }

  .icon--menu-toggle {
    --size: calc(1rem + 4vmin);
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    stroke-width: 6;
  }
  
  .icon-group {
    transform: translateX(0);
    transition: transform var(--nav-duration) var(--ease);
  }
  
  .icon--menu {
    stroke: var(--color-primary);
  }
  
  .icon--close {
    stroke: var(--color-secondary);
    transform: translateX(-100%);
  }
  
  .main-navigation {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: transform var(--nav-duration);
    z-index: 1;
  }
  .main-navigation:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
    transform-origin: 0 50%;
    z-index: -1;
  }
  .main-navigation ul {
    font-size: 5vmin;
    font-family: var(--font-heading);
    width: 100%;
  }
  .main-navigation li {
    --border-size: 1vmin;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  .main-navigation li:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--border-size);
    background-color: var(--color-secondary);
    transform-origin: 0 50%;
    transform: translateX(-100%) skew(15deg);
  }
  .main-navigation a {
    display: inline-block;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    color: var(--color-secondary);
    line-height: 1;
    text-decoration: none;
    user-select: none;
    padding: var(--space) calc(var(--space) * 2) calc(var(--space) + var(--border-size) / 2);
    transform: translateY(100%);
  }
  
  .main-navigation a:hover{
    color: #012970;
  }

  .main-content {
    margin: 6rem auto;
    max-width: 70ch;
    padding: 0 calc(var(--space) * 2);
    transform: translateX(0);
    transition: transform calc(var(--nav-duration) * 2) var(--ease);
  }
  .main-content > * + * {
    margin-top: calc(var(--space) * var(--line-height));
  }
  
  .main-navigation-toggle:checked ~ label .icon--menu-toggle .icon-group {
    transform: translateX(100%);
  }
  .main-navigation-toggle:checked ~ .main-content {
    transform: translateX(10%);
  }
  .main-navigation-toggle:checked ~ .main-navigation {
    transition-duration: 0s;
    transform: translateX(0);
  }
  .main-navigation-toggle:checked ~ .main-navigation:after {
    animation: nav-bg var(--nav-duration) var(--ease) forwards;
  }
  .main-navigation-toggle:checked ~ .main-navigation li:after {
    animation: nav-line var(--duration) var(--ease) forwards;
  }
  .main-navigation-toggle:checked ~ .main-navigation a {
    animation: link-appear calc(var(--duration) * 1.5) var(--ease) forwards;
  }
  .main-navigation-toggle:checked ~ .main-navigation li:nth-child(1):after, .main-navigation-toggle:checked ~ .main-navigation li:nth-child(1) a {
    animation-delay: calc((var(--duration) / 2) * 1 * 0.125);
  }
  .main-navigation-toggle:checked ~ .main-navigation li:nth-child(2):after, .main-navigation-toggle:checked ~ .main-navigation li:nth-child(2) a {
    animation-delay: calc((var(--duration) / 2) * 2 * 0.125);
  }
  .main-navigation-toggle:checked ~ .main-navigation li:nth-child(3):after, .main-navigation-toggle:checked ~ .main-navigation li:nth-child(3) a {
    animation-delay: calc((var(--duration) / 2) * 3 * 0.125);
  }
  .main-navigation-toggle:checked ~ .main-navigation li:nth-child(4):after, .main-navigation-toggle:checked ~ .main-navigation li:nth-child(4) a {
    animation-delay: calc((var(--duration) / 2) * 4 * 0.125);
  }
  
  @keyframes nav-bg {
    from {
      transform: translateX(-100%) skewX(-15deg);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes nav-line {
    0% {
      transform: scaleX(0);
      transform-origin: 0 50%;
    }
    35% {
      transform: scaleX(1.001);
      transform-origin: 0 50%;
    }
    65% {
      transform: scaleX(1.001);
      transform-origin: 100% 50%;
    }
    100% {
      transform: scaleX(0);
      transform-origin: 100% 50%;
    }
  }
  @keyframes link-appear {
    0%, 25% {
      transform: translateY(100%);
    }
    50%, 100% {
      transform: translateY(0);
    }
  }

  @import url(https://fonts.googleapis.com/css?family=Montserrat:900|Raleway:400,400i,700,700i);
  /*** VARIABLES ***/
  /* Colors */
  /*** EXTEND ***/
  /* box-shadow */
  ol.gradient-list > li::before, ol.gradient-list > li {
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  }
  

  /* ------------------------------------------------------------------------------------------------
                                        Why us section
   ------------------------------------------------------------------------------------------------- */
  ol.gradient-list {
    counter-reset: gradient-counter;
    list-style: none;
    margin: 1.75rem 0;
    padding-left: 1rem;
    
  }
  ol.gradient-list > li {
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
    box-shadow: 0 20px 50px rgba(0,0,0,0.2);
    color: #012970;
  }
  ol.gradient-list > li::before, ol.gradient-list > li::after {
    background: linear-gradient(135deg, #83e4e2 0%, #a2ed56 100%);
    border-radius: 1rem 1rem 0 1rem;
    content: "";
    height: 3rem;
    left: -1rem;
    overflow: hidden;
    position: absolute;
    top: -1rem;
    width: 3rem;
  }
  ol.gradient-list > li::before {
    align-items: flex-end;
    content: counter(gradient-counter);
    color: #1d1f20;
    display: flex;
    font: 900 1.5em/1 "Montserrat";
    justify-content: flex-end;
    padding: 0.125em 0.25em;
    z-index: 1;
  }
  ol.gradient-list > li:nth-child(10n+1):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.2) 0%, rgba(253, 220, 50, 0.2) 100%);
  }
  ol.gradient-list > li:nth-child(10n+2):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.4) 0%, rgba(253, 220, 50, 0.4) 100%);
  }
  ol.gradient-list > li:nth-child(10n+3):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.6) 0%, rgba(253, 220, 50, 0.6) 100%);
  }
  ol.gradient-list > li:nth-child(10n+4):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.8) 0%, rgba(253, 220, 50, 0.8) 100%);
  }
  ol.gradient-list > li + li {
    margin-top: 2rem;
  }












#typewriter {
  font-size: 2em;
  margin: 0;
  font-family: "Courier New";
 

}
#typewriter:after {
  content: "||";
  animation: blink 500ms linear infinite alternate;
  color: red;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
 }
  100% {
    opacity: 1;
 }
}
@-moz-keyframes blink {
  0% {
    opacity: 0;
 }
  100% {
    opacity: 1;
 }
}
@keyframes blink {
  0% {
    opacity: 0;
 }
  100% {
    opacity: 1;
 }
}


*, *:before, *:after {
box-sizing: border-box;
margin: 0;
padding: 0;
} 

.button {
width: 200px;
display: flex;
cursor: pointer;
align-items: center;
margin-left: auto;
margin-right: auto;
margin-top: 30px;
text-align: center; 




}
@media screen and (max-width: 600px) {
.button {
  width: 100%;
}
}
.button:hover .button__border-circle {
transform: translateX(60px);
}
.button:hover .button__mask-circle {
clipPath: circle(25px at 85px);
}
.button__text {
z-index: 1;
font-size: 25px;
margin-right: -18px;
color: #0f0f0f;
letter-spacing: 0.05em;
}
.button__wrapper {
position: relative;
display: flex;
align-items: center;
}
.button__arrow {
left: 35px;
height: 5px;
width: 60px;
display: flex;
position: absolute;
align-items: center;
background-color: #b3d23f;
}
.button__arrow:after {
content: '';
width: 0;
height: 0;
top: -5px;
right: -7px;
position: absolute;
border-top: 7px solid transparent;
border-bottom: 7px solid transparent;
border-left: 7px solid #b3d23f;
}
.button__border-circle {
width: 50px;
height: 50px;
border-radius: 50%;
border: 2px solid #bfbfbf;
transition: transform 987ms;
}
.button__mask-circle {
width: 68px;
height: 68px;
position: absolute;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
clipPath: circle(25px);
transition: clipPath 987ms;
}
.button__small-circle {
width: 16px;
height: 16px;
border-radius: 50%;
transform: translateX(60px);
background-color: #b3d23f;
}

.nav-logo {
  height: 12%;
  width: 12%;
  margin-top: 5px;
  margin-left: 10px;
}


/* ########################################################################################### */
                                /* New crad products.js */
/* ########################################################################################### */


.product_icon {
  opacity: 0;
  font-size: 18px;
  color: #fff;
  will-change: transform;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

h2 {
  pointer-events: none;
}
.product-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
}
.product-container .product-card {
  position: relative;
  width: 400px;
  height: 200px;
  background-color: rgb(156, 162, 167);
  overflow: hidden;
  margin-bottom: 4px;
}


.product-container .product-card h2 {
  z-index: 99;
  font-family: "Poppins", sans-serif;
  position: absolute;
  bottom: 0;
  right: 130px;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.product-container .product-card .fa-arrow-right {
  z-index: 100;
  position: absolute;
  right: 75px;
  bottom: 25px;
  font-size: 40px;
  cursor: pointer;
}
.product-container .product-card p {
  z-index: 99;
  position: absolute;
  top: 20px;
  right: 70px;
  color: rgb(218, 69, 69);
  opacity: 1;
  font-size: 12px;
  letter-spacing: 1px;
  writing-mode: vertical-lr;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.product-container .product-card .pic2 {
  z-index: 100;
  width: 300px;
  height: 200px;
  background-image: url("https://images.unsplash.com/photo-1525543907410-b2562b6796d6?ixlib=rb-0.3.5&s=9ff8e5e718a6a40cbd0e1471235912f4&auto=format&fit=crop&w=3452&q=80");
  background-size: 100% 100%;
  filter: grayscale(100%);
}

.product-container .product-card .pic1 {
  z-index: 100;
  width: 300px;
  height: 200px;
  background-image: url("https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=889&q=80");
  background-size: 100% 100%;
  filter: grayscale(100%);
}

.product-container .product-card .social {
  position: absolute;
  left: 60px;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 180px;
  height: 64px;
  border-radius: 80px;
}
.product-container .product-card .social i:nth-of-type(1) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.product-container .product-card .social i:nth-of-type(2) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.product-container .product-card .social i:nth-of-type(3) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.product-container .product-card .social i:nth-of-type(4) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.product-container .product-card:hover i {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.product-container .product-card button {
  position: absolute;
  right: 14px;
  bottom: 14px;
  width: 30px;
  height: 30px;
  background-color: #DA4D1D;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  mix-blend-mode: hard-light;
}
.product-container .product-card button i {
  font-size: 3rem;
}
.product-container .product-card:hover button {
  transform: scale(16.5);
}
.product-container .product-card:hover p {
  color: #fff;
}
.product-container .product-card:hover .pic {
  filter: grayscale(0);
}
.product-container .card2 .pic {
  background-image: url("https://images.unsplash.com/photo-1528785198459-ec50485704c7?ixlib=rb-0.3.5&s=3a2fc3039516555bbb2e9cd2967bd321&auto=format&fit=crop&w=1537&q=80");
}
.product-container .card2 button {
  background-color: #2b26c3;
}

.dr {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 100px;
}

/* Testimonials */


#demo {
  background: linear-gradient(112deg, #ffffff 50%, antiquewhite 50%);
  max-width: 900px;
  margin: auto
}

.carousel-caption {
  position: initial;
  z-index: 10;
  padding: 5rem 8rem;
  color: rgba(78, 77, 77, 0.856);
  text-align: center;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: bold;
  line-height: 2rem
}

@media(max-width:767px) {
  .carousel-caption {
      position: initial;
      z-index: 10;
      padding: 3rem 2rem;
      color: rgba(78, 77, 77, 0.856);
      text-align: center;
      font-size: 0.7rem;
      font-style: italic;
      font-weight: bold;
      line-height: 1.5rem
  }
}

.carousel-caption img {
  width: 6rem;
  border-radius: 5rem;
  margin-top: 2rem
}

@media(max-width:767px) {
  .carousel-caption img {
      width: 4rem;
      border-radius: 4rem;
      margin-top: 1rem
  }
}

#image-caption {
  font-style: normal;
  font-size: 1rem;
  margin-top: 0.5rem
}

@media(max-width:767px) {
  #image-caption {
      font-style: normal;
      font-size: 0.6rem;
      margin-top: 0.5rem
  }
}

.testimonials-i {
  background-color: rgb(223, 56, 89);
  padding: 1.4rem
}

@media(max-width:767px) {
  .testimonials-i {
      padding: 0.8rem
  }
}

.carousel-control-prev {
  justify-content: flex-start
}

.carousel-control-next {
  justify-content: flex-end
}

.carousel-control-prev,
.carousel-control-next {
  transition: none;
  opacity: unset;
}

/* About us */

/* Rest of the CSS designs are used from the section of whyus */
.aboutus-gradient-list{
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  counter-increment: gradient-counter;
  margin-top: 1rem;
  min-height: 3rem;
  padding: 1rem 1rem 1rem 3rem;
  position: relative;
  font-family: "Times New Roman", Times, serif;
  font-size: 1.2rem;
  font-style: italic;
  color: #012970;
} 



/* GRAPHICS DESIGNS */



.hello {
  opacity: 1 !important;
}
.full {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.full .content {
  background-color: rgba(0,0,0,0.75) !important;
  height: 100%;
  width: 100%;
  display: grid;
}
.full .content img {
  left: 50%;
  transform: translate3d(0, 0, 0);
  animation: zoomin 1s ease;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.byebye {
  opacity: 0;
}
.byebye:hover {
  transform: scale(0.2) !important;
}
.gallery {
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 8px;
}
.gallery img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 16px #333;
  transition: all 1.5s ease;
}
.gallery img:hover {
  box-shadow: 0 0 32px #333;
}
.gallery .content {
  padding: 4px;
}
.gallery .gallery-item {
  transition: grid-row-start 300ms linear;
  transition: transform 300ms ease;
  transition: all 0.5s ease;
  cursor: pointer;
}
.gallery .gallery-item:hover {
  transform: scale(1.025);
}
@media (max-width: 600px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
}
@media (max-width: 400px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
@-moz-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@-webkit-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@-o-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}


/* flooting button */

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:50px;
	right:50px;
	/* background-color:#0C9; */
	/* color:#FFF; */
	/* border-radius:50px; */
	/* text-align:center; */
	/* box-shadow: 2px 2px 3px #999; */
}

.my-float{
}